import React, { useRef } from "react";
import { Welcome } from "../components";

import { INTRO, HELLO, NAME } from "../utils/constants";

export function WelcomeContainer() {
  const endOfComponent = useRef(null);

  const scrollToProjects = () => {
    endOfComponent.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Welcome>
      <Welcome.Header>
        <Welcome.HeaderText>
          {HELLO}
          <Welcome.HeaderSpan>, I'm</Welcome.HeaderSpan> {NAME}
        </Welcome.HeaderText>
        <Welcome.Section>
          <Welcome.SubHeaderText>{INTRO}</Welcome.SubHeaderText>
        </Welcome.Section>
      </Welcome.Header>
      <Welcome.Break />
      <Welcome.LinkButton name="about me" onClick={scrollToProjects}>
        about me
      </Welcome.LinkButton>
      <Welcome.Break />
      <Welcome.Break />
      <Welcome.Break />
      <div ref={endOfComponent} />
    </Welcome>
  );
}
