import React from 'react'
import {
  Container,
  Header,
  HeaderText,
  HeaderSpan,
  LinkButton,
  Section,
  SubHeaderText,
  Break
} from './styles/welcome'

export default function Welcome({ children, ...restProps }) {
  return (
    <Container {...restProps}>{children}</Container>
  )
}

Welcome.Section = function WelcomeSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>
}

Welcome.Header = function WelcomeHeader({ children, ...restProps }) {
  return <Header {...restProps}>{children}</Header>
}

Welcome.HeaderText = function WelcomeHeaderText({ children, ...restProps }) {
  return <HeaderText {...restProps}>{children}</HeaderText>
}

Welcome.HeaderSpan = function WelcomeHeaderSpan({ children, ...restProps }) {
  return <HeaderSpan {...restProps}>{children}</HeaderSpan>
}

Welcome.SubHeaderText = function WelcomeSubHeaderText({ children, ...restProps }) {
  return <SubHeaderText {...restProps}>{children}</SubHeaderText>
}

Welcome.LinkButton = function WelcomeLinkButton({ children, ...restProps }) {
  return <LinkButton {...restProps}>{children}</LinkButton>
}

Welcome.Break = function WelcomeBreak({ children }) {
  return <Break>{children}</Break>
}
