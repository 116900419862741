import React from 'react'
import {
  Container,
  Background,
  Header,
  Input,
  RefreshColors,
  DrawArea
} from './styles/painter'

export default function Painter({ children, ...restProps }) {
  return (
    <Container {...restProps}>{children}</Container>
  )
}

Painter.Background = function PainterBackground({ children, ...restProps }) {
  return <Background {...restProps}>{children}</Background>
}

Painter.Header = function PainterHeader({ children, ...restProps }) {
  return <Header {...restProps}>{children}</Header>
}

Painter.Header = function PainterHeader({ children, ...restProps }) {
  return <Header {...restProps}>{children}</Header>
}

Painter.Input = function PainterInput({ children, ...restProps }) {
  return <Input {...restProps}>{children}</Input>
}

Painter.RefreshColors = function PainterRefreshColors({ children, ...restProps }) {
  return <RefreshColors {...restProps}>{children}</RefreshColors>
}

Painter.DrawArea = function PainterDrawArea({ children, ...restProps }) {
  return <DrawArea {...restProps}>{children}</DrawArea>
}
