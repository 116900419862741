import React from 'react'
import { Container, Section, Header, Button } from './styles/counter'

export default function Counter({ children, ...restProps }) {
  return (
    <Container {...restProps}>{children}</Container>
  )
}

Counter.Section = function CounterSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>
}

Counter.Header = function CounterHeader({ children, ...restProps }) {
  return <Header {...restProps}>{children}</Header>
}

Counter.Button = function CounterButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>
}
