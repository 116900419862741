import React, { useEffect, useRef } from "react";
import { Resume } from "../components";

import {
  RESUME_SUMMARY,
  CONTACT_EMAIL,
  CONTACT_LINKEDIN,
  CONTACT_CITY,
  EXPERIENCE_DSCOUT_SE,
  EXPERIENCE_DSCOUT_QA,
  EXPERIENCE_SPOTHERO,
  SKILLS,
} from "../utils/constants";

export function ResumeContainer(props) {
  const topOfComponent = useRef();

  const scrollTo = () => {
    topOfComponent.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    return scrollTo();
  });

  return (
    <Resume>
      <div ref={topOfComponent}>
        <Resume.Background>
          <Resume.Column smaller>
            <Resume.Name>Anneliese Hernandez</Resume.Name>
            <Resume.JobTitle>Software Engineer</Resume.JobTitle>
            <Resume.Break />
            <Resume.SectionTitle>Summary</Resume.SectionTitle>
            <Resume.Text>{RESUME_SUMMARY}</Resume.Text>
            <Resume.Break />
            <Resume.Break />
            <Resume.SectionTitle>Contact</Resume.SectionTitle>
            <Resume.Text>
              <div>{CONTACT_EMAIL}</div>
              <div>{CONTACT_LINKEDIN}</div>
            </Resume.Text>
            <Resume.Text>{CONTACT_CITY}</Resume.Text>
          </Resume.Column>
          <Resume.Column>
            <Resume.SectionTitle>Experience</Resume.SectionTitle>
            <Resume.JobTitle position>Software Engineer</Resume.JobTitle>
            <Resume.Text>dscout, {CONTACT_CITY}</Resume.Text>
            <Resume.Text>{EXPERIENCE_DSCOUT_SE}</Resume.Text>
            <Resume.JobTitle position>QA Engineer</Resume.JobTitle>
            <Resume.Text>dscout, {CONTACT_CITY}</Resume.Text>
            <Resume.Text>{EXPERIENCE_DSCOUT_QA}</Resume.Text>
            <Resume.JobTitle position>QA Analyst</Resume.JobTitle>
            <Resume.Text>SpotHero, {CONTACT_CITY}</Resume.Text>
            <Resume.Text>{EXPERIENCE_SPOTHERO}</Resume.Text>
            <Resume.SectionTitle>Education</Resume.SectionTitle>
            <Resume.JobTitle position>
              Washington University in St. Louis
            </Resume.JobTitle>
            <Resume.Text>Bachelor of Arts, Psychology</Resume.Text>
            <Resume.SectionTitle>Skills</Resume.SectionTitle>
            <Resume.Text>{SKILLS}</Resume.Text>
          </Resume.Column>
        </Resume.Background>
      </div>
    </Resume>
  );
}
