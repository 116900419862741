import styled from 'styled-components'

export const Container = styled.div`
  background: #b894b3;
  @media (max-width: 900px) {

  }

  @media print {
   display: none;
   padding: 0;
 }
`

export const Icon = styled.div`

`

export const Nav = styled.nav`
  text-decoration: none;
  position: fixed;
  background: rgba(0,0,0,0.1);
  padding: 0.5em;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1300px) {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    background: rgba(0,0,0,0.8);
    z-index: 1;
  }
`
