import React from 'react'
import { NavLink } from 'react-router-dom';
import styled from "styled-components";

import { Navigation } from '../components'

export function NavigationContainer() {
  return (
    <Navigation>
      <Navigation.Nav>
        <Navigation.Icon>
          <NavItem exact to="/" activeclassname={activeClassName}>Home</NavItem>
          <NavItem to="/resume" activeclassname={activeClassName}>Resume</NavItem>
        </Navigation.Icon>
      </Navigation.Nav>
    </Navigation>
  )
}

const activeClassName = 'selected'

const NavItem = styled(NavLink).attrs({
    activeClassName
  })`
  color: white;
  padding: 1em;
  text-transform: uppercase;
  font-size: 1rem;
  text-decoration: none;

  &:hover, &:focus {
    color: #372C35;
  }

  &.${activeClassName} {
    font-weight: bold;
  }

  @media (max-width: 1300px) {
    &:hover, &:focus {
      color: #b894b3;
    }
  }
`;
