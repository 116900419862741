import React, { useState, useEffect } from 'react'
import randomColor from 'randomcolor'
import { Painter } from '../components'

export function PainterContainer() {
  const [color, setColor] = useState([])
  const [activeColor, setActiveColor] = useState('')
  const [isDrawing, setIsDrawing] = useState(false)

  const getColors = () => {
    const baseColor = randomColor().slice(1)
    fetch(`https://www.thecolorapi.com/scheme?hex=${baseColor}&mode=monochrome`)
      .then(res => res.json())
      .then(res => {
        setColor(res.colors.map((color) => color.hex.value))
        setActiveColor(res.colors[0].hex.value)
      })
  }

  useEffect(getColors, [])

  const colorInputs = color.map((color, index) => {
    return (
      <Painter.Input
        key={index}
        style={{background: `${color}`}}
        onClick={() => setActiveColor(color)}
      >
      </Painter.Input>
    )
  })

  const draw = (e) => {
    setIsDrawing(true)
    const xCoordinates = e.clientX
    const yCoordinates = e.clientY
    console.log(xCoordinates, yCoordinates)

    console.log('inside draw', isDrawing)
  }

  const stopDrawing = () => {
    setIsDrawing(false)
  }

  return (
    <Painter>
      <Painter.Background style={{borderTop: `8px solid ${activeColor}`}}>
        <Painter.Header>
          {colorInputs}
          <Painter.RefreshColors onClick={getColors} />
        </Painter.Header>
        <Painter.DrawArea onMouseDown={(e) => draw(e)} onMouseUp={stopDrawing} />
      </Painter.Background>
    </Painter>
  )
}
