import React from 'react'
import { Container, Section, Title, Break } from './styles/aboutme'

export default function About({ children, ...restProps }) {
  return (
    <Container {...restProps}>{children}</Container>
  )
}

About.Title = function AboutTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

About.Section = function AboutSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>
}

About.Break = function AboutBreak({ children }) {
  return <Break>{children}</Break>
}
