import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';

import { GlobalStyles } from './global-styles'
import App from './App';

ReactDOM.render(
    <>
      <GlobalStyles/>
      <App />
    </>,
  document.getElementById('root')
);
