import React from 'react'
import { Link } from 'react-router-dom';

import { Container, Nav, Icon } from './styles/navigation'

export default function Navigation({ children, ...restProps }) {
  return (
    <Container {...restProps}>{children}</Container>
  )
}

Navigation.Nav = function NavigationNav({ children, ...restProps }) {
  return <Nav {...restProps}>{children}</Nav>
}

Navigation.Icon = function NavigationIcon({ children, ...restProps }) {
  return <Icon {...restProps}>{children}</Icon>
}
