import React, { useState, useEffect } from 'react'
import randomColor from 'randomcolor'
import { Counter } from '../components'

export function CounterContainer() {
  const [count, setCount] = useState(0)
  const [color, setColor] = useState('')

  function increment() {
    setCount(count + 1)
  }

  function decrement() {
    setCount(count - 1)
  }

  useEffect(() => {setColor(randomColor)}, [count])

  return (
    <Counter id="counter">
      <Counter.Section>
        <Counter.Header style={{color: color}}>{count}</Counter.Header>
        <Counter.Button onClick={increment}>count up</Counter.Button>
        <Counter.Button onClick={decrement}>count down</Counter.Button>
      </Counter.Section>
    </Counter>
  )
}
