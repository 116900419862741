import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 80vh;
  padding-top: 20vh;
  background: linear-gradient(#b894b3, #ad85a9, #a3759d);

  @media (max-width: 1000px) {

  }
`

export const Header = styled.header`
`

export const HeaderText = styled.h2`
  font-family: 'Pacifico', cursive;
  font-size: 1.5em;
  font-weight: normal;
`

export const HeaderSpan = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
`

export const Section = styled.section`

`

export const SubHeaderText = styled.h3`
  font-weight: normal;
  font-size: 1.4rem;
`

export const LinkButton = styled.button`
  background: #F0E9EF;
  color: #372C35;
  border: 1px solid #735C70;
  border-radius: 1em;
  margin: 0 auto;
  padding: 1em 1.2em;
  text-decoration: none;
  font-weight: bold;

  &:focus, &:hover {
    background: #80677D;
    color: #F0E9EF;
    cursor: pointer;
  }
`

export const Break = styled.div`
  height: 5em;
`
