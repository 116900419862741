import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-image: linear-gradient(#a3759d, #996692, #8a5c84);
  padding-bottom: 8em;

  @media (max-width: 900px) {

  }
`

export const Title = styled.h2`
  font-family: 'Pacifico', cursive;
  font-size: 1.7rem;
  font-weight: normal;
`

export const Section = styled.div`
  width: 50%;
  line-height: 1.4em;
  margin: 0 auto;

  @media (max-width: 950px) {
    width: 75%;
  }
`

export const Break = styled.div`
  height: 1.5em;
`
