import styled from 'styled-components'

export const Container = styled.div`
  margin: auto;
  height: 90vh;
  padding-top: 10vh;
  background: linear-gradient(#b894b3, #ad85a9, #a3759d);

  @media (max-width: 1000px) {

  }

`

export const Background = styled.div`
  font-size: 1em;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 80%;
  min-height: 200px;
  max-width: 850px;
  background: white;
  color: #363636;

  @media (max-width: 1000px) {
    height: auto;
    font-size: 18px;
    min-width: 90%;
  }

  @media (max-width: 700px) {

  }

  @media print {
    font-size: 10pt
  }
`
export const Header = styled.header`
`

export const Input = styled.div`
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 2px solid black;

  &:hover {
    cursor: pointer;
  }
`

export const RefreshColors = styled.div`
  height: 20px;
  margin: 4px;
  background: url('../../../images/icons8-refresh-24.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: fill;


  &:hover {
    cursor: pointer;
  }
`

export const DrawArea = styled.canvas`
  border-left: 1px solid black;
  display: block;
  width: 100%;


  &:hover {
    cursor: crosshair;
  }
`
