import React from 'react'
import {
  Container,
  Background,
  Column,
  Name,
  JobTitle,
  SectionTitle,
  Text,
  Break
} from './styles/resume'

export default function Resume({ children, ...restProps }) {
  return (
    <Container {...restProps}>{children}</Container>
  )
}

Resume.Background = function ResumeBackground({ children, ...restProps }) {
  return <Background {...restProps}>{children}</Background>
}

Resume.Column = function ResumeColumn({ children, ...restProps }) {
  return <Column {...restProps}>{children}</Column>
}

Resume.Name = function ResumeName({ children, ...restProps }) {
  return <Name {...restProps}>{children}</Name>
}

Resume.JobTitle = function ResumeJobTitle({ children, ...restProps }) {
  return <JobTitle {...restProps}>{children}</JobTitle>
}

Resume.SectionTitle = function ResumeSectionTitle({ children, ...restProps }) {
  return <SectionTitle {...restProps}>{children}</SectionTitle>
}

Resume.Text = function ResumeText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>
}

Resume.Break = function ResumeBreak({ children, ...restProps }) {
  return <Break {...restProps}>{children}</Break>
}
