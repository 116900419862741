import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: linear-gradient(#b894b3, #ad85a9, #a3759d, #996692, #8a5c84);


  @media (max-width: 1000px) {

  }
`

export const Section = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: center;
  border-radius: 2em;
  background: rgba(92, 92, 92, 0.2);

  @media (max-width: 1000px) {
    width: 70%;
  }
`

export const Header = styled.h2`

`
export const Button = styled.button`
  background: white;
  border: 1px solid black;
  border-radius: 1em;
  margin: 1em;
  padding: 1.1em 1.5em;

  &:focus, &:hover {
    box-shadow: 0px 0px 10px aqua;
  }
`
