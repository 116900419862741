import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { NavigationContainer as Navigation } from './containers/navigation'
import { WelcomeContainer as Welcome } from './containers/welcome'
import { AboutMeContainer as About } from './containers/aboutme'
import { ResumeContainer as Resume } from "./containers/resume"
import { CounterContainer as Counter } from "./containers/counter"
import { PainterContainer as Painter } from "./containers/painter"

function App() {
  return (
    <Router>
      <Navigation />
      <Switch>
        <Route exact path="/">
          <Welcome />
          <About />
        </Route>
        <Route path="/resume">
          <Resume />
        </Route>
        <Route path="/counter">
          <Counter />
        </Route>
        <Route path="/paint-app">
          <Painter />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
