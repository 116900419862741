export const HELLO = "hello";

export const NAME = "Anneliese";

export const INTRO = "QA engineer to frontend software engineer";

export const ABOUT_ME = "about me";

export const INTRO_PARAGRAPH1 =
  "My journey into the world of tech began at a startup in Chicago. I've spent the past four years or so learning more about the industry while working as a QA Engineer, squashing bugs & writing automated tests. I've always been interested in how people use & interact with technology. This interest has helped me so much in my role as a QA Engineer, figuring out how people could (& would) find ways to break our products & software. After being part of the team that pokes holes and finds gaps in tech (while making it better for our end user), I've decided I'd like to continue to do that while being the person who helps build it.";

export const INTRO_PARAGRAPH2 =
  "I've enjoyed exploring & discovering what would come next for me because with tech, the possibilities seem endless -- there's always more to learn and more to do. I'd love to build websites & products that improve people's lives & make an impact. I'm passionate about finding creative solutions to problems. My eye for detail & time spent as a QA Engineer have been great assets to me as I've made the switch to development, and I'm so excited to see where this path leads.";

export const INTRO_PARAGRAPH3 =
  "Ultimately, I would love to find a role at a company with an established culture of mentorship or sponsorship and with a proven focus on diversity, equity, & inclusion, particularly on hiring, retaining, and investing in people who have been historically excluded from tech.";

export const RESUME_SUMMARY =
  "I'm a Quality Engineer turned Software Engineer & an experienced advocate for quality & the end user. As a mostly self-taught developer, I enjoy being challenged & solving problems as I continue to learn. I'm eager to find ways to grow in my coding abilities while being a voice for quality & contributing to the design of new products & features. I would love to find a role at a company with an established culture of mentorship or sponsorship and with a proven focus on diversity, equity, & inclusion, particularly on hiring & retaining people from historically excluded backgrounds in tech.";

export const CONTACT_EMAIL = "anneliesenhernandez@gmail.com";

export const CONTACT_LINKEDIN = "linkedin.com/in/anneliesehernandez";

export const CONTACT_CITY = "Chicago, IL";

export const EXPERIENCE_DSCOUT_SE = (
  <>
    <div>
      • Plan, build, and iterate on new features for a product team, primarily
      using React
    </div>
    <div>
      • Provide thorough test coverage for features as they are being developed,
      both unit & end-to-end tests
    </div>
    <div>
      • Collaborate with design and product to deliver impactful features with
      the end user in mind
    </div>
  </>
);

export const EXPERIENCE_DSCOUT_QA = (
  <>
    <div>
      • Reviewed requirements & ensure quality of features before release
    </div>
    <div>• Wrote thorough end-to-end tests using RSpec & Capybara</div>
    <div>
      • Collaborate with product, design & developers on team to think through
      both the technical implementation of new features as well as the
      end-to-end user experience
    </div>
  </>
);

export const EXPERIENCE_SPOTHERO = (
  <>
    <div>
      • Tested new features in native apps & on web to ensure quality before
      release
    </div>
    <div>
      • Discovered & helped eliminate bugs through regression testing with
      functional team
    </div>
    <div>
      • Collaborated alongside designers & developers to assist with new feature
      development
    </div>
  </>
);

export const SKILLS = (
  <>
    <div>- JavaScript, React</div>
    <div>- Ruby</div>
    <div>- HTML/CSS</div>
  </>
);
