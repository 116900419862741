import React from 'react'
import { About } from '../components'

import { INTRO_PARAGRAPH1, INTRO_PARAGRAPH2, INTRO_PARAGRAPH3, ABOUT_ME } from "../utils/constants"

export function AboutMeContainer() {
  return (
    <About>
      <About.Section>
        <About.Title>{ABOUT_ME}</About.Title>
        {INTRO_PARAGRAPH1}
      </About.Section>
      <About.Break />
      <About.Section>
        {INTRO_PARAGRAPH2}
      </About.Section>
      <About.Break />
      <About.Section>
        {INTRO_PARAGRAPH3}
      </About.Section>
    </About>
  )
}
