import styled from 'styled-components'

export const Container = styled.div`
  margin: auto;
  padding-top: 10vh;
  background: linear-gradient(#b894b3, #ad85a9, #a3759d);
  overflow: auto;

  @media print {
    padding-top: 0;
    background: none;
  }

  @media (max-width: 700px) {

  }
`

export const Background = styled.div`
  margin: 2em auto;
  box-sizing: border-box;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 850px;
  background: white;
  color: #363636;

  @media print {
    display: block;
    width: 100%;
    margin: 0;
    columns: 40px 2;
  }

  @media (max-width: 1000px) {
    height: auto;
    font-size: 18px;
    min-width: 90%;
  }

  @media (max-width: 700px) {
    height: auto;
    flex-direction: column;
    font-size: 16px;
    margin-top: 70px;
  }
`

export const Column = styled.div`
  box-sizing: border-box;
  margin: 1em 0.5em 1em 2em;
  width: ${props => props.smaller ? '45%' : '55%'};

  @media (max-width: 700px) {
    margin-left: 3em;
    width: 80%;
  }

  @media print {
    margin: 20px;
    margin-top: 90px;
    width: 100%;
    padding: 0;
  }
`

export const Name = styled.h1`
  text-transform: uppercase;
`

export const JobTitle = styled.h2`
  font-weight: ${props => props.position ? 'bold' : 'normal'};
  font-size: 1em;
  text-transform: ${props => props.position ? 'none' : 'uppercase'};
`

export const SectionTitle = styled.h2`
  font-size: 1.2em;
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-color: #DBC9D8;
  text-underline-offset: .5em;

  @media print {
    margin-top: 50px;
  }
`

export const Text = styled.p`

`

export const Break = styled.div`
  height: 30px;

`
